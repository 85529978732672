<template>
  <Modal :activeModal="activeModal" @closeModal="closeModal">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        Filtros
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>


      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12"  class="pt-0 pb-0 mt-0">
                <v-row>
                  <v-col cols="12" sm="5" class="pt-0 pb-0 mt-0">
                    <v-switch
                      v-model="form.paymentDate"
                      inset
                      class="mt-0"
                      dense
                      :label="'Buscar pela data de baixa do prêmio'"
                      :hide-details="true"
                    ></v-switch>
                  </v-col>
                </v-row>

              </v-col>


              <v-col cols="12" sm="6">
                <FiltroRangeDate
                  class="mr-3 range-date"
                  @input="filtrar"
                  :value="form.rangeDate"
                  :labelBtn="'Selecionar Data'"
                  :actionLabels="{ apply: 'OK' }"
                  style="width: 100%;"
                  :disableBtnClear="false"
                />
              </v-col>

              <v-col cols="12" sm="6">
                <v-btn  
                  class="mr-3 br-btn-cancelar"
                  :class="{'isActive': form.largePrize}"
                  @click="switchLargePrize"
                >Prêmios altos</v-btn>
                <v-btn 
                  class="mr-3 br-btn-cancelar"
                  :class="{'isActive': form.premiosWeb}"
                  @click="switchPrizeWeb"
                >Prêmios web</v-btn>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="6" class="pb-0 pt-0">
            <v-select
              dense
              v-model="form.statusPayment"
              :items="listSelect"
              label="Selecione o status dos prêmios"
              outlined
            />
          </v-col>

          <v-col cols="6" class="pb-0 pt-0">
            <v-select
              dense
              v-model="form.loteria"
              :items="loterias"
              label="Loterias"
              outlined
            />
          </v-col>

          <v-col cols="6" class="pb-0 pt-0">
            <autoCompleteEntities 
              :entityProps.sync="form.entity"
              :typesEntities="['4']"
              :label="'Revenda'"
              :isOutlined="true"
              clearable
            />
          </v-col>

          <v-col cols="6" class="pb-0 pt-0">
            <v-select
              dense
              v-model="form.user"
              :items="userList"
              label="Usuário"
              outlined
              clearable
            />
          </v-col>

           <v-col cols="6" class="pb-0 pt-0">
            <v-currency-field
              v-model="form.value"
              label="Valor do prêmio"
              prefix="R$"
              outlined
              dense
            />
          </v-col>

          <v-col cols="6" class="pb-0 pt-0">
            <v-text-field 
              type="number"
              v-model="form.nrjogo"
              label="Nº jogo"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="modal-cadastro-footer">
        <v-btn :color="variables.colorPrimary" @click="closeModal" class="br-btn br-btn-cancelar">Cancelar</v-btn>
        <v-btn type="submit" :color="variables.colorPrimary" @click.prevent="buscarGanhadores" class="br-btn" :loading="loadingBtnBusca">Buscar</v-btn>
      </v-card-actions>
    </v-card>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { validationMixin } from 'vuelidate'
// import { required } from 'vuelidate/lib/validators'
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import moment from 'moment'
// import { errorSnackbar, successSnackbar } from '@/core/service/utils'
// import { each, size } from 'lodash'
// import validacao from './validacao'

// eslint-disable-next-line
const namespaceStore = ''

export default {
  name: 'ModalJogos',
  mixins: [validationMixin],
  props: {
    loterias: {
      type: Array,
      default: (() => [])
    },
    loadingBtnBusca: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Modal: () => import('@/views/components/modal'),
    FiltroRangeDate: () => import('@/views/components/filtroRangeDate'),
    autoCompleteEntities: () => import('../../../components/autoCompleteEntities'),
  },
  data: () => ({
    activeModal: false,
    loading: false,
    nameRevenda: '',
    dadosJogos: [],
    rangeDate: {
      start: moment().format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD')
    },    
    listSelect: [
      { text: 'Prêmio pago', value: 'B' },
      { text: 'Prêmio não pago', value: 'N' },
      { text: 'Prêmio expirado', value: 'E' }
    ],
    form: {
      loteria: '',
      entity: {},
      user: '',
      value: 0,
      statusPayment: 'N',
      paymentDate: false,
      rangeDate: {
        start: moment().format('YYYY-MM-DD'),
        end: moment().format('YYYY-MM-DD')
      },
      largePrize: true,
      premiosWeb: false
    },
    config: {
      locale: 'pt-BR',
      prefix: '',
      suffix: '',
      length: 100
    }
  }),
  
  computed: {
    ...mapGetters('ganhadores', ['userList']),
    variables: () => variables
  },
  mounted () {
    this.getUsers()
    Events.$on('ganhadores::modal::maisFiltros::open', () => {
      this.activeModal = true
    })

    Events.$on('ganhadores::modal::maisFiltros::close', () => {
      this.activeModal = false
    })
  },
  methods: {
    ...mapActions('resgate', ['editarItem']),
    ...mapActions('entities', ['getEntities']),
    ...mapActions('ganhadores', ['getUsers']),
    buscarDados (val) {
      Events.$emit('buscar:dados::lancamentos', val)
    },

    closeModal() {
      this.activeModal = false
    },

    filtrar (e) {
      this.form.rangeDate = {
        start: e.start,
        end: e.end
      }
    },

    buscarGanhadores () {
      console.log('teste')
      if (!this.form.largePrize && this.form.statusPayment === 'B') {
        this.$swal({
          icon: 'warning',
          text: `Não é possível buscar por prêmios baixos e baixados`,
          showCancelButton: false,
          showConfirmButton: false,
        })
        return
      }

      this.$emit('buscarGanhadores', this.form)
    },

    switchLargePrize () {
      this.form.largePrize = !this.form.largePrize
    },

    switchPrizeWeb () {
      this.form.premiosWeb = !this.form.premiosWeb
    }
  },

  beforeDestroy () {
    Events.$off('ganhadores::modal::baixar')
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';

  .range-date::v-deep {
    .v-btn {
      width: 100%;
    }
  }

  .br-btn-cancelar.isActive {
    background-color: $colorSecondary !important;
    color: #FFF !important;
    border-color: $colorSecondary !important;
  }
</style>